const config_api = require("../../../config/config").config_api;
const utils = require("../../../utils");
const axios = require('axios');
const moment = require('moment')

function getAllGroup(data,callback){
    axios({
        url: config_api.kienthietGetAll+'?'+utils.convertToQuery(data),
        method: 'Get',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        }   

    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

function createGroup(data,callback){
    axios({
        url:config_api.kienthietCreate,
        method: 'Post',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data:data    

    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}
function getAllKTMN(data,callback){
    axios({
        url: config_api.ktmnGetAll+'?'+utils.convertToQuery(data),
        method: 'Get',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        }   

    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}
function updateKTMN(data,callback){
    axios({
        url: config_api.ktmnUpdate,
        method: 'post',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data   

    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}
function deleteKTMN(data,callback){
    axios({
        url: config_api.ktmnDelete,
        method: 'delete',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data:{id:data.kID}   

    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}
function getAllKTMT(data,callback){
    axios({
        url: config_api.ktmtGetAll+'?'+utils.convertToQuery(data),
        method: 'Get',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        }   

    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}
function updateKTMT(data,callback){
    axios({
        url: config_api.ktmtUpdate,
        method: 'post',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data   

    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}
function deleteKTMT(data,callback){
    axios({
        url: config_api.ktmtDelete,
        method: 'delete',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data:{id:data.kID}   

    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}
function exportKTMT(data,callback){
    axios({
        url: config_api.ktmtExport+'?'+utils.convertToQuery(data),
        method: 'Get',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        responseType: 'blob',

    })
    .then(result => {
        const type = result.headers['content-type'];
        const blob = new Blob([result.data], { type: type, encoding: 'UTF-8' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const now = moment().format('DD-MM-YYYY HH-mm')
        link.download = `export${now}.xlsx`;
        link.click();
        return(callback(false,true) ); 
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}

module.exports={
    createGroup,
    getAllGroup,
    getAllKTMN,
    updateKTMN,
    deleteKTMN,
    getAllKTMT,
    updateKTMT,
    deleteKTMT,
    exportKTMT
}